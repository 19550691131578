import styled from "styled-components";
import {
  Rem,
  BP,
} from "../../../commons/Theme";

export const WhyToJoinWrapper = styled.div`
  padding: 0;
  padding-bottom: 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 93rem;
  @media (${BP.tablet}) {
    max-width:1248px;
    margin: 0 auto;
    gap: 3rem;
    padding: ${Rem(100)} 2rem;
  }
`;

export const StyledWhyToJoinTitle = styled.h1`
  text-align: left;
  font-size: ${Rem(32)};
  padding: 0 1.25rem;
  padding-bottom: 1rem;
  @media (${BP.tablet}) {
    font-size: ${Rem(40)};
    padding: 0;
    padding-bottom: 2rem;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;
  padding: 2rem 1.25rem;
  @media (${BP.tablet}) {
    padding: 0;
    flex-direction: row;
    gap: 2rem;
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const WrapperImage = styled.div`
  padding-bottom: 1rem;
  width: 80px;
  display: flex;
  justify-content: center;
`;

export const StyledLabel = styled.h3`
  font-size: ${Rem(18)};
  font-weight: 600; 
  text-align: center;
  line-height: 2rem;
  @media (${BP.tablet}) {
    min-height: 58px;
  }
`;

export const StyledText = styled.p`
  font-size: ${Rem(18)};
  text-align: center;
  font-weight: 300;
`;

export const StyledLine = styled.div`
    border-top: 1.5px solid #AAA9A9;
    display: block;
    width: 100px;
    @media (${BP.ipad}) {
      width: 140px;
    }
`;
