import React, { useContext } from "react";
import {
  StyledWhyToJoinTitle,
  WhyToJoinWrapper,
  WrapperContent,
  WrapperItem,
  WrapperImage,
  StyledLine,
  StyledLabel,
  StyledText,
} from "./style.jsx";
import { DataContext } from "../../../contexts/DataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default ({ content }) => {
  const {
    pageImages,
  } = useContext(DataContext);
  const getImageData = (image) => pageImages.find((e) => e.path === image);

  return (
    <WhyToJoinWrapper >
      <StyledWhyToJoinTitle
        dangerouslySetInnerHTML={{ __html: content.title }}
      />
      <WrapperContent>
        {content.containsList?.map((e) => (
          <WrapperItem key={e.id}>
            <WrapperImage>
              <GatsbyImage image={getImage(getImageData(e.image))} alt={e.id} />
            </WrapperImage>
            <StyledLabel dangerouslySetInnerHTML={{ __html: e.title }} />
            <StyledLine/>
            <StyledText dangerouslySetInnerHTML={{ __html: e.text }} />
          </WrapperItem>
        ))}
      </WrapperContent>
    </WhyToJoinWrapper>
  );
};
